@import './reset.css';

:root {
  --blackish: #040407;
  --whitish: #fbfbf8;
  --primary-blue: #01579b;
  --primary-blue-lighter: #3e8dd6;
  --lightblue: rgb(203, 228, 235);
  --green: rgb(108, 179, 105);
}

[hidden],
template {
  display: none !important;
}

html,
body {
  background-color: var(--whitish);
  color: var(--blackish);
  height: 100%;
  margin: 0;
  padding: 0;
}

body {
  box-sizing: border-box;
  overflow-y: scroll;
  padding: 20px 0;
}

body::after {
  content: '';
  display: block;
  padding-bottom: 1.8em;
}

body > noscript {
  background-color: red;
  color: white;
  font-size: 20px;
  font-family: monospace;
  left: 0;
  line-height: 20px;
  padding: 40px;
  position: fixed;
  right: 0;
  text-align: center;
  top: calc(50% - 50px);
  z-index: 1;
}

body > section {
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
  padding-left: 20px;
  padding-right: 20px;
}

@import './typography.css';
@import './buttons.css';
