@import '../base.css';
@import '../components/cookie-banner.css';
@import '../components/modal.css';
@import '../components/top-nav.css';

.game-area {
  align-items: flex-start;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  position: relative;
  text-align: center;
  user-select: none;
  z-index: 0;
}

button[name="refresh-puzzle"] {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: var(--primary-blue);
  background-image: url(../../images/refresh-icon-white.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 100%;
  border: none;
  box-shadow: 0 0 10px 10px rgba(251, 251, 248, 1);
  display: block;
  height: 48px;
  padding: 10px;
  position: absolute;
  right: 20px;
  top: -60px;
  width: 48px;
  z-index: 2;
}

button[name="refresh-puzzle"]:focus,
button[name="refresh-puzzle"]:hover {
  background-color: var(--primary-blue-lighter);
  outline: none;
}

button[name="refresh-puzzle"].spin {
  animation-duration: 0.5s;
  animation-name: spin;
  outline: none;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.game-instructions {
  align-items: center;
  background-color: rgba(251, 251, 248, 0.6);
  bottom: 0;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  left: 0;
  padding: 0 40px;
  position: absolute;
  right: 0;
  top: -28.5px;  /* Offset to keep text more vertially centered */
  z-index: 1;
}

.game-instructions > p {
  margin-top: 0;
}

.game-area,
.game-instructions {
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
}

.game-instructions::before {
  background-color: var(--whitish);
  box-shadow: 0 0 125px 200px var(--whitish);
  content: '';
  height: 1px; /* box-shadow does not render in Safari for 0 height elements */
  left: 0;
  position: absolute;
  right: 0;
  top: 50%;
  z-index: -1;
}

.letter-grid,
.word-list,
.puzzle-timer {
  box-sizing: border-box;
}

.puzzle-timer {
  display: block;
  font-size: 1.2em;
  padding: 0 20px;
  text-align: center;
  width: 100%;
}

.selected-text {
  border: 1px solid transparent;
  border-radius: 20px;
  display: block;
  height: 1em;
  margin-left: auto;
  margin-right: auto;
  padding: 4px 8px;
  text-align: center;
  text-transform: uppercase;
  transition: all linear 0.25s;
}

.selected-text:not(:empty) {
  background-color: var(--lightblue);
  border-color: black;
}

.word-list {
  align-items: space-around;
  display: block;
  display: flex;
  flex-flow: row wrap;
  font-size: 14px;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0 20px 5px 20px;
}

.word-list li {
  display: inline-block;
  margin: 2.5px 5px;
  position: relative;
  text-transform: capitalize;
}

.word-list li[data-is-found]::after {
  border-bottom: 2px solid var(--blackish);
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 50%;
}

@media (min-width: 600px) {
  .word-list li {
    margin: 10px;
  }
}

.word-selection {
  background-color: rgba(158, 207, 223, 0.5);
  border-radius: 20px;
  border: 1px solid black;
  box-sizing: border-box;
  display: block;
  font-size: 1.2em;
  left: 0;
  position: absolute;
  top: 0;
  z-index: -3;
}

.word-selection.diagonal {
  height: 30px;
  top: 5px;
  transform-origin: 20px 15px;
}

.word-selection.active {
  background-color: var(--green);
  z-index: -2;
}

.letter-grid {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  cursor: pointer;
  display: inline-block;
  padding: 10px 20px;
  position: relative;
  user-select: none;
  width: 100%;
}

.letter-grid > li > ul {
  display: flex;
  width: 100%;
}

.letter-grid > li > ul > li {
  flex: 1;
  max-width: 40px;
  position: relative;
  text-transform: uppercase;
}

.letter-grid > li > ul > li::before {
  content: '';
  display: block;
  padding-bottom: 100%;
}

.letter-grid > li > ul > li > span {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}
