body {
  font-family: sans-serif;
  font-size: 16px;
  line-height: 1.15;
}

h1,
h2,
h3 {
  text-align: center;
}

h1 {
  font-family: serif;
  font-size: 1.8em;
  font-weight: 400;
  line-height: 40px;
  margin-bottom: 20px;
  margin-top: 0;
}

h2 {
  font-size: 1.5em;
  font-weight: bold;
  line-height: 1.17;
  margin-bottom: 0.83em;
  margin-top: 0.83em;
}

h3 {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 1em;
  margin-top: 1em;
}

p {
  font-size: 1em;
  margin-bottom: 1em;
  margin-top: 1em;
}
