#navigation {
  align-self: flex-end;
  background-color: var(--whitish);
  border-radius: 2px;
  box-shadow: 0 0 5px 0 rgba(4, 4, 7, 0.5);
  display: block;
  left: 80px;
  overflow: hidden;
  position: fixed;
  top: 20px;
  transform: translateY(calc(-100% - 30px));
  transition: transform 0.15s ease-out;
  z-index: 1;
}

#navigation[aria-expanded="true"] {
  transform: translateY(0%);
}

#navigation .nav-menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

#navigation a {
  color: var(--blackish);
  display: block;
  padding: 15px;
  text-decoration: none;
}

#navigation li:not(:last-child) {
  margin-bottom: 8px;
}

#navigation a:active,
#navigation a:focus,
#navigation a:hover {
  background-color: var(--lightblue);
  color: var(--blackish);
  outline: 0;
  text-decoration: none;
}

[aria-controls="navigation"] {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  background-color: var(--primary-blue);
  border-radius: 100%;
  border: none;
  box-shadow: 0 0 10px 10px rgba(251, 251, 248, 1);
  display: block;
  height: 48px;
  left: 20px;
  padding: 10px;
  position: fixed;
  top: 20px;
  width: 48px;
  z-index: 2;
}

@media screen and (min-width: 500px) {
  #navigation {
    left: calc(50% - 170px);
  }

  [aria-controls="navigation"] {
    left: calc(50% - 230px);
  }
}

[aria-controls="navigation"]:hover,
[aria-controls="navigation"]:focus,
[aria-controls="navigation"]:active {
  background-color: var(--primary-blue-lighter);
  outline: 0;
}

[aria-controls="navigation"] .hamburger-box {
  display: inline-block;
  height: 100%;
  position: relative;
  width: 100%;
}

[aria-controls="navigation"] .hamburger,
[aria-controls="navigation"] .hamburger::after,
[aria-controls="navigation"] .hamburger::before {
  background-color: #fff;
  border-radius: 4px;
  height: 4px;
  position: absolute;
  transition-duration: 0.15s;
  transition-property: transform;
  transition-timing-function: ease;
  width: 100%;
}

[aria-controls="navigation"] .hamburger {
  display: block;
  margin-top: -2px;
  top: 50%;
  transition-duration: 75ms;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

[aria-controls="navigation"] .hamburger::before,
[aria-controls="navigation"] .hamburger::after {
  content: '';
  display: block;
}

[aria-controls="navigation"] .hamburger::before {
  top: -200%;
  transition: top 75ms ease 0.12s, opacity 75ms ease;
}

[aria-controls="navigation"] .hamburger::after {
  bottom: -200%;
  transition: bottom 75ms ease 0.12s, transform 75ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

[aria-controls="navigation"][aria-expanded="true"] .hamburger {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

[aria-controls="navigation"][aria-expanded="true"] .hamburger::before {
  opacity: 0;
  top: 0;
  transition: top 75ms ease, opacity 75ms ease 0.12s;
}

[aria-controls="navigation"][aria-expanded="true"] .hamburger::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 75ms ease, transform 75ms cubic-bezier(0.215, 0.61, 0.355, 1) 0.12s;
}
