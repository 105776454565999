/* Modal wrapper for positioning */
.modal {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10;
}

/* Backdrop */
.modal::before {
  background: var(--blackish);
  bottom: 0;
  content: '';
  left: 0;
  opacity: 0.4;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 0;
}

/* The actual modal contents */
.modal > * {
  align-items: center;
  background-color: var(--whitish);
  border-radius: 4px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  padding: 20px;
  z-index: 1;
}

.modal h3,
.modal p {
  margin-top: 0;
}
