.cookie-banner {
  align-items: center;
  bottom: 0;
  display: flex;
  left: 0;
  justify-content: center;
  position: fixed;
  right: 0;
  z-index: 3;
}

.cookie-banner > * {
  align-items: center;
  background-color: var(--whitish);
  box-shadow: 0 0 5px 0 rgba(4, 4, 7, 0.5);
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding: 40px;
}

.cookie-banner p {
  margin-top: 0;
  width: 100%;
}

.cookie-banner a {
  padding: 15.5px 0;
}

@media (min-width: 600px) {
  .cookie-banner > * {
    border-radius: 4px;
    margin: 20px;
  }
}
