button {
  appearance: none;
  background-color: var(--whiteish);
  border: 1px solid var(--blackish);
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  padding: 16px;
}

button.primary {
  background-color: var(--primary-blue);
  border-color: var(--primary-blue);
  color: white;
  transition: background-color 0.15s ease-out;
}

button.primary:hover,
button.primary:active,
button.primary:focus {
  background-color: var(--primary-blue-lighter);
  border-color: var(--primary-blue-lighter);
  outline: 0;
}

button.small {
  padding: 5px 10px;
}
